import React, { Component}  from "react";

class Footer extends Component {
    render() {
        return (
            <div id="Footer" variant="dark">
                <p>Copyright 2019 Edwin Hung - Made with React, React-Bootstrap, and Love.  Deployed with AWS.  Contact me for more information</p> 
            </div>
        );
    }
}

export default Footer; 